/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Home as HomeIcon, FileText as FileIcon, User as UserIcon, LogIn as LoginIcon, RefreshCcw as Refresh, CreditCard } from 'react-feather';
import { NavLink, withRouter } from "react-router-dom";
import { openDialog } from 'redux-reactstrap-modal';
// import LoadingBar from 'react-redux-loading-bar';
import Popup from "reactjs-popup";
import _, { toUpper } from "lodash";


import {
    randomBuy, subscribe, buy, clearNextEvent, mobileNoUpdate,
    loadLanguage, randomBuyWithWallet, 
    subscribeWithWallet, buyWithWallet, loadNextEventLocalStorage, clearNextEventLocalStorage, showNotificationModal,loginFrimiUser,getMobileNumber, getCommonWalletInfo, getCommonWalletInfoNoConsentCheck
} from "../actions/index";
import { MODALS, EVENTS, STRING, INSTRUMENTS } from '../config/';
import nlb_logo from '../images/lucky_footer_logo.png';
// import black_logo from '../images/BlackGold.gif';
import wallet_icon from '../images/wallet.png';
import dialog_logo from '../images/dialoglogo.png';
import img from '../images/GSSPWEB.gif';


import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage
} from 'react-switch-lang';
import en from '../config/en.json';
import sin from '../config/sin.json';
import tml from '../config/tml.json';
import { currencyConvertorNoSymbol } from '../utils/currency-convertor'
setTranslations({ en, sin, tml });
setDefaultLanguage('en');
setLanguageCookie();

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectLang: 'en',
            bodyClass: "en",
            open: true,
            cwCallback:false
        };
        this.langChange = this.langChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }



    checkUrlContainsParams = (url, field) => {
        if (url.indexOf('?' + field + '=') != -1)
            return true;
        else if (url.indexOf('&' + field + '=') != -1)
            return true;
        return false
    }

    componentDidUpdate() {
        this.props.getMobileNumber();
        if (!(this.props.token === "" || this.props.token === null || this.props.token === "null")||this.state.cwCallback) {

            if (this.props.nextEvent) {
                const { event, data } = this.props.nextEvent;
                switch (event) {
                    case EVENTS.RANDOM_BUY:
                        this.props.randomBuy(data);
                        return "";
                    case EVENTS.BUY:
                        //this.props.buy(data);
                        if(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null'){
                            this.props.openDialog("MODAL_NIC");
                        }else{
                            this.props.openDialog("MODAL_BUY", data);
                            this.props.clearNextEvent();
                        }
                        
                        return "";
                    case EVENTS.SUBSCRIBE:
                        //this.props.subscribe(data);
                        if(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null'){
                            this.props.openDialog("MODAL_NIC");
                        }else{
                            var pack = _.find(this.props.packages, { package_name: data.lottery_name });                                         
                            this.props.openDialog("MODAL_SUB", _.merge(pack, data));
                            this.props.clearNextEvent();
                        }
                        return "";
                    case EVENTS.SUBSCRIBE_WITH_WALLET:
                        //this.props.subscribeWithWallet(data);
                        this.props.subscribe(data);
                        this.props.clearNextEventLocalStorage();
                        this.props.clearNextEvent();
                        return "";
                    case EVENTS.WALLET_RANDOM_BUY:
                        this.props.randomBuyWithWallet(data);
                        this.props.clearNextEventLocalStorage();
                        this.props.clearNextEvent();
                        return "";
                    case EVENTS.WALLET_BUY:
                        this.props.buyWithWallet(data);
                        this.props.clearNextEventLocalStorage();
                        this.props.clearNextEvent();
                        return "";
                    case EVENTS.ACCOUNT:
                        if (this.props.history.location.pathname !== "/account") {
                            this.props.history.push("/account");
                        } else {
                            window.location.reload();
                        }
                        return "";
                    case EVENTS.SEARCH_AND_BUY:
                        if(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null'){
                            this.props.openDialog("MODAL_NIC");
                        }else{
                            this.props.openDialog("MODAL_SEARCH", data);
                            this.props.clearNextEvent();
                        }
                        return "";
                    default:
                        return "";
                }
            }

        } else if (!(this.props.nic_update === "null" || this.props.nic_update === null)) {
            if (this.props.nextEvent) {
                const { event, data } = this.props.nextEvent;
                switch (event) {
                    case EVENTS.RANDOM_BUY:
                        this.props.randomBuy(this.props.nic_update);
                        return "";
                    case EVENTS.BUY:
                        this.props.openDialog("MODAL_BUY", data);
                        this.props.clearNextEvent();
                        return "";
                    case EVENTS.SUBSCRIBE:
                        var pack = _.find(this.props.packages, { package_name: data.lottery_name });                                         
                            this.props.openDialog("MODAL_SUB", _.merge(pack, data));
                            this.props.clearNextEvent();
                            return "";
                    case EVENTS.SEARCH_AND_BUY:
                    if(localStorage.getItem('nic') === null || localStorage.getItem('nic') === 'null'){
                        this.props.openDialog("MODAL_NIC");
                    }else{
                        this.props.openDialog("MODAL_SEARCH", data);
                        this.props.clearNextEvent();
                    }
                    return "";
                    default:
                        return "";
                }

            }

        }

    }
    componentDidMount() {
        let searchContent = this.props.location.search && this.props.location.search
        let pathContent = window.location.pathname; 

        if (this.checkUrlContainsParams(searchContent, 'cw-callback')) {
            const urlParams = new URLSearchParams(searchContent);
            const cwCallback = urlParams.get('cw-callback');
            const statusWallet = urlParams.get('status');

           

            if(cwCallback && statusWallet===STRING.WALLET_CALLBACK_SUCCESS){

                this.props.loadNextEventLocalStorage();
                this.setState({cwCallback:true})
            }else{
                this.props.clearNextEventLocalStorage(); 
                this.setState({cwCallback:false})
                this.props.showNotificationModal({ type: "danger", message: "Common Wallet Payment Canceled" })
            }
            
        }

        if (pathContent.includes('FriMi')) {  // Checking for FriMi in the path
            const uuid = pathContent.split('/')[1];  // Extract UUID 
            const source = 'frimi'; 
    
            if (uuid && source === INSTRUMENTS.FRIMI) {
                let sessionSource = sessionStorage.getItem("source");
                if (!sessionSource || sessionSource !== toUpper(INSTRUMENTS.FRIMI)) {
                    this.props.loginFrimiUser(uuid); // Pass the UUID to login 
                }
            } else {
                this.props.showNotificationModal({ type: "danger", message: STRING.INVALID_LOGIN_ATTEMPT });
            }
        }

        if (localStorage.getItem('language-loaded')) {
            this.setState({ selectLang: localStorage.getItem('language-loaded') });
            // this.props.loadLanguage(ls.get('language'));
            setLanguage(localStorage.getItem('language-loaded'));


            //Have to find a work around to this
            document.body.classList.remove('en');
            document.body.classList.remove('sin');
            document.body.classList.remove('tml');


            document.body.classList.add(localStorage.getItem('language-loaded'));
        }
    }

    langChange(event) {
        this.setState({ selectLang: event.target.value });
        this.props.loadLanguage(event.target.value);
        setLanguage(event.target.value);


        //Have to find a work around to this
        document.body.classList.remove('en');
        document.body.classList.remove('sin');
        document.body.classList.remove('tml');


        document.body.classList.add(event.target.value);

        if (this.props.token && this.props.token !== 'undefined') {
            // this.props.setLanguage({ "language": event.target.value });
        }
    }
    openModal() {
        this.setState({ open: true });
    }
    closeModal() {
        this.setState({ open: false });
    }


    renderPopup() {

        var pointer_style = {
            cursor: 'pointer',
            display: 'block',
        };

        return (
            <Popup
                open={this.state.open}
                modal
                closeOnDocumentClick
                onClose={this.closeModal} >
            </Popup>
        )
    }

    refreshWalletBalance() {
        this.props.getCommonWalletInfoNoConsentCheck();
    }


    render() {
        const { t, wallet_balance } = this.props;
        var lan = getLanguage();

        return (
          <div className="menu-bar">
            {/* <LoadingBar className="loading-bar bg-warning" /> */}
            <div className="d-flex flex-column flex-md-row align-items-stretch col-12">
              <div className="px-0 d-flex mx-lg-auto justify-content-between justify-content-lg-around align-items-center text-center">
                <NavLink to="/">
                  <img src={nlb_logo} alt="NLB Logo" className="logo" />
                </NavLink>
                <div className="px-3">
                  {/* <NavLink to="/">
                    <img src={black_logo} alt="NLB Logo" className="logo" />
                  </NavLink> */}
                </div>

                <div className="language nav-link d-md-flex mobile-menu flex-column flex-md-row ml-md-auto mx-1 align-items-center mobile-lang">
                  <div
                    className="btn-group language-selection float-right"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      value="en"
                      type="button"
                      title="English"
                      onClick={this.langChange}
                      className={
                        this.state.selectLang === "en"
                          ? `btn btn-sm active`
                          : `btn btn-sm`
                      }
                    >
                      En
                    </button>
                    <button
                      value="sin"
                      type="button"
                      title="සිංහල"
                      onClick={this.langChange}
                      className={
                        this.state.selectLang === "sin"
                          ? `btn btn-sm active`
                          : `btn btn-sm`
                      }
                    >
                      සිං
                    </button>
                    <button
                      value="tml"
                      type="button"
                      title="தமிழ்"
                      onClick={this.langChange}
                      className={
                        this.state.selectLang === "tml"
                          ? `btn btn-sm active`
                          : `btn btn-sm`
                      }
                    >
                      த
                    </button>
                  </div>
                </div>

                {/* <img src={dialog_logo} alt="Dialog Logo" className="ml-4" /> */}
                {/* <div className="language d-flex d-md-none mobile-menu flex-column flex-md-row ml-md-auto mx-1 align-items-end">
                            <select className="languageSelect" hidden onChange={this.langChange} value={this.state.selectLang}>
                                <option value="en">English</option>
                                <option value="sin">SINHALA</option>
                                <option value="tml">TAMIL</option>
                            </select>
                        </div> */}
              </div>

              <div className="dsktp-menu">
                {/* {this.renderPopup()} */}
                {/*
                        <ul className="nav nav-pills nav-fill nav-justified p-0">
                            <li className="nav-item">
                                <NavLink exact activeClassName="active" className="nav-link mx-1 d-flex flex-column flex-md-row align-items-center" to="/">
                                    <div><HomeIcon size={16} /></div>
                                    <div>Home</div>
                                </NavLink >
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link mr-1 d-flex flex-column flex-md-row align-items-center" to="/results">
                                    <div><FileIcon size={16} /></div>
                                    <div>Results</div>
                                </NavLink >
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link mr-1 d-flex flex-column flex-md-row align-items-center" to="/account">
                                    <div><UserIcon size={16} /></div>
                                    <div>Account</div>
                                </NavLink >
                            </li>
                        </ul>
                        */}

                <div className="d-flex nav nav-pills nav-fill nav-justified p-0">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link dsktp-d-flex mobile-menu d-flex flex-column flex-md-row mx-1 align-items-center justify-content-center "
                    to="/"
                  >
                    <div>
                      <HomeIcon size={16} />
                    </div>
                    <div>{t("menu.home_button")}</div>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    className="nav-link dsktp-d-flex d-flex mobile-menu flex-column flex-md-row mx-1 align-items-center"
                    to="/results"
                  >
                    <div>
                      <FileIcon size={16} />
                    </div>
                    <div>{t("menu.result")}</div>
                  </NavLink>

                  <div className="language nav-link d-md-flex mobile-menu flex-column flex-md-row ml-md-auto mx-1 align-items-center desktop-lang">
                    <div
                      className="btn-group language-selection float-right"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        value="en"
                        type="button"
                        title="English"
                        onClick={this.langChange}
                        className={
                          this.state.selectLang === "en"
                            ? `btn btn-sm active`
                            : `btn btn-sm`
                        }
                      >
                        En
                      </button>
                      <button
                        value="sin"
                        type="button"
                        title="සිංහල"
                        onClick={this.langChange}
                        className={
                          this.state.selectLang === "sin"
                            ? `btn btn-sm active`
                            : `btn btn-sm`
                        }
                      >
                        සිං
                      </button>
                      <button
                        value="tml"
                        type="button"
                        title="தமிழ்"
                        onClick={this.langChange}
                        className={
                          this.state.selectLang === "tml"
                            ? `btn btn-sm active`
                            : `btn btn-sm`
                        }
                      >
                        த
                      </button>
                    </div>
                  </div>
                  <div className="flex-md-row mx-1 align-items-center mobile_order_two">
                    {wallet_balance == "" ? (
                      ""
                    ) : (
                      <div
                        className="wallet-balance nav-link always"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {/* <div><CreditCard size ={16}/>
                                     </div> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            paddingLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              textTransform: "capitalize",
                              paddingRight: "5px",
                            }}
                          >
                            <div className="wallet-balance-img">
                              <img
                                src={wallet_icon}
                                width="20px"
                                height="20px"
                              ></img>
                            </div>
                            <div className="wallet-balance-text">
                              Available Balance
                            </div>
                          </div>
                          {/* <div style={{textTransform:"capitalize"}}>Rs. {currencyConvertorNoSymbol(wallet_balance)}</div> */}
                          <div style={{ textTransform: "capitalize" }}>
                            Rs.{" "}
                            {currencyConvertorNoSymbol(
                              sessionStorage.getItem("balance")
                            )}
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-refresh-balance refresh-balance mx-auto btn-warning"
                            style={{ borderRadius: "40px" }}
                            onClick={() => this.refreshWalletBalance()}
                          >
                            <Refresh style={{ color: "#060000" }} size={14} />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <NavLink
                    activeClassName="active"
                    className="nav-link dsktp-d-flex d-flex mobile-menu flex-column flex-md-row mx-1 align-items-center balance_order_one"
                    style={{ width: "auto" }}
                    to="/account"
                  >
                    <div>
                      <UserIcon size={16} />
                    </div>
                    <div>
                      {this.props.mobile_number != ""
                        ? this.props.mobile_number
                        : t("menu.account")}
                    </div>
                    {/* <div>{t("menu.account")}</div> */}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ openDialog, randomBuy, buy, subscribe, clearNextEvent, loadLanguage, randomBuyWithWallet, subscribeWithWallet, buyWithWallet, loadNextEventLocalStorage, clearNextEventLocalStorage, showNotificationModal, loginFrimiUser,getMobileNumber, getCommonWalletInfo, getCommonWalletInfoNoConsentCheck }, dispatch);
}

function mapStateToProps({ nextEvent, token, nic_update, packages, mobile_number, wallet_balance }) {
    return { nextEvent, token, nic_update, packages, mobile_number, wallet_balance };
}
export default withRouter(translate(connect(mapStateToProps, mapDispatchToProps)(Menu)));
